import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from "NstyleComponents";
import { useHistory } from 'react-router-dom';
import * as actions from '../../../actions/auth/signInAction';
import { DocTitle } from '../../common/DocTitle';
import { imageCdnUrls } from '../../common/ImageCdnUrls';
import './_style.scss';

const LogoutPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const status = useSelector((state: any) => state.auth.status);

    const redirectToLogin = useCallback(() => {
        history.push('/auth/login' + history.location.search);
    }, [history]);

    //Dispatch to log the user out
    useEffect(() => {
        dispatch(actions.logout());
    }, [dispatch]);

    //When the dispatch finishes and the status is updated, redirect to login
    useEffect(() => {
        if (status === 'LoggedOut') {
            redirectToLogin();
        }
    }, [status, redirectToLogin]);

    //If the status hasn't been updated in 30 seconds, redirect to login so they're not left hanging
    useEffect(() => {
        const timerId = setTimeout(() => {
            redirectToLogin();
        }, 30000);

        return () => clearTimeout(timerId);
    }, [redirectToLogin]);

    return (
        <div style={{ background: `url(${imageCdnUrls.doubleBlobBackground}) no-repeat center / cover` }} className="logout-page">
            <DocTitle syncTo="Logging out..." />
            <div className="logout-page__content">
                <Loader showLoader type={"--branded"} size={"--larger"} />
            </div>
        </div>
    );
};

export default LogoutPage;
