import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { Route, withRouter} from 'react-router-dom';
import qs from 'query-string';
import camelcaseKey from 'camelcase-keys';
import SignInPage from './signIn/SigninPage';
import TwoFASelectionPage from './twoFactorAuthentication/TwoFASelectionPage';
import OtpConfirmation from '../auth/twoFactorAuthentication/OtpConfirmation';
import ResetPasswordPage from './password/ResetPasswordPage';
import * as actions from '../../actions/user/userAction';
import AccountLockedPage from './accountLocked/AccountLockedPage';
import CustomerSelectionPage from "./customerSelection/CustomerSelectionPage";
import settings from "../../configs/appSettings";
import SendPasswordResetLink from './password/SendPasswordResetLink';
import LogoutPage from './logout/LogoutPage';

class AuthContainer extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);

        const qsParams = this.validateReturnUrl();

        const isSSO = qsParams.sso === "spinit";

        this.state = {
            referrer: {
                path: '',
                platform: qsParams.referrer,
                platformRequestedRedirectUrl: qsParams.returnUrl ? qsParams.returnUrl + window.location.hash : "",
                isSSO,
                customerId: qsParams.ti || ''
            },
            step: 'signIn',
            status: '',
            signInVm: {
                working: false,
                error: ''
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state != null) {
            this.setState({...this.state,
                status: nextProps.status,
                referrer: {
                    path: nextProps.location.state.redirectFrom.pathname,
                }
            });
        } else {
            this.setState({...this.state, status: nextProps.status});
        }
    }

    componentDidUpdate() {
        if(this.state.status === "LoggedIn")  {
            if (this.state.referrer.platform != null) {
                if (this.props.referrer.platform.redirectTo.trim().length > 0) {
                    window.location.replace(this.props.referrer.platform.redirectTo);
                }
                else {
                    this.props.getUserInfo(this.props.userId);
                    this.props.history.push('/app');
                }
            }else if (this.state.referrer.path.trim().length > 0) {
                this.props.history.push(this.state.referrer.path);
            }else {
                this.props.getUserInfo(this.props.userId);
                this.props.history.push('/app');
            }
        }
    }

    validateReturnUrl () {
        let options = camelcaseKey(qs.parse(window.location.search));

        if (options.returnUrl) {
            const matchFullURLs = new RegExp(/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/);
            const matchAnyURIScheme = new RegExp(/^([a-z][a-z0-9+.-]*):/);
            const matchesAFullURL = matchFullURLs.test(options.returnUrl);
            const matchesAnyURIScheme = matchAnyURIScheme.test(options.returnUrl);

            if (matchesAFullURL || matchesAnyURIScheme) {
                options.returnUrl = options.returnUrl.replace(/^(?:\/\/|[^/]+)*\//, '');
            }
        }

        return options;
    }

    checkForAndInformParentOfTimeout = () => {
        if (window.top.location !== window.self.location) {
            window.top.postMessage("auth-timeout", settings.northBaseUrl);
        }
    };

    render() {
        this.checkForAndInformParentOfTimeout();

        return (
            <div>
                <Route
                    exact
                    path={`${this.props.match.url}`}
                    render={(props) => (
                        <SignInPage {...props} referrer={this.state.referrer} />
                    )}
                />
                <Route
                    path={`${this.props.match.url}/login`}
                    render={(props) => (
                        <SignInPage {...props} referrer={this.state.referrer} />
                    )}
                />
                <Route
                    path={`${this.props.match.url}/requestIdentificationMethods`}
                    render={(props) => (
                        <TwoFASelectionPage
                            {...props}
                            referrer={this.state.referrer}
                        />
                    )}
                />
                <Route
                    path={`${this.props.match.url}/confirmIdentificationCode`}
                    render={(props) => (
                        <OtpConfirmation
                            {...props}
                            referrer={this.state.referrer}
                        />
                    )}
                />
                <Route
                    path={`${this.props.match.url}/sendPasswordResetLink`}
                    render={(props) => (
                        <SendPasswordResetLink
                            {...props}
                            referrer={this.state.referrer}
                        />
                    )}
                />
                <Route
                    path={`${this.props.match.url}/resetPassword/:nonce`}
                    render={(props) => (
                        <ResetPasswordPage
                            {...props}
                            referrer={this.state.referrer}
                        />
                    )}
                />
                <Route
                    path={`${this.props.match.url}/accountLocked/:email`}
                    render={(props) => (
                        <AccountLockedPage
                            {...props}
                            referrer={this.state.referrer}
                        />
                    )}
                />
                <Route
                    path={`${this.props.match.url}/customer-selection`}
                    render={(props) => (
                        <CustomerSelectionPage
                            {...props}
                            referrer={this.state.referrer}
                        />
                    )}
                />
                <Route
                    path={`${this.props.match.url}/logout`}
                    render={(props) => (
                        <LogoutPage {...props} />
                    )}
                />
            </div>
        );
    }
}

AuthContainer.propTypes = {
    status: PropTypes.string.isRequired,
    modalities: PropTypes.array,
    nonce: PropTypes.string,
    redirectFrom: PropTypes.string,
    match: PropTypes.object,
    history: PropTypes.object,
    referrer: PropTypes.object,
    userId: PropTypes.string,
    getUserInfo: PropTypes.func,
    location: PropTypes.object
};

AuthContainer.contextTypes = {
    router: PropTypes.object
};

function mapDispatchToProps(dispatch) {
    return {
        getUserInfo: (userId) => {
            dispatch(actions.getUserInfo(userId));
            dispatch(actions.getProducts());
        }
    };
}

function mapStateToProps(state, ownProps) {
    let referrer = { platform: {}};
    if (ownProps.referrer != null)
        referrer = ownProps.referrer;
    else
        referrer.platform.redirectTo = state.auth.userInfo.platformRedirectUrl;

    return {
        status: state.auth.status,
        modalities: state.auth.modalities,
        nonce: state.auth.nonce,
        referrer: referrer,
        userId: state.auth.userInfo.userId
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthContainer));
